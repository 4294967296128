require('./bootstrap');
require('jquery.easing/jquery.easing.min.js');
const bodymovin = require('lottie-web/build/player/lottie.js');
require('multiscroll.js/jquery.multiscroll.min.js');
require('multiscroll.js/dist/jquery.multiscroll.extensions.min.js');
require('leaflet/src/Leaflet.js');
require('inputmask/dist/jquery.inputmask.min.js');
var DG = require('2gis-maps');
require('./protection');

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});
	

  $(window).on('load', function () {
      $('#loading').fadeOut().end().delay(400);
    });
	
	function GetDoc(docname){	
	
	$.get( "/"+docname, function( data ) {
		//var temp = jQuery.parseJSON(data);
	$( ".modal-image" ).removeAttr('src'); 
		$( ".block-modal-title" ).html( data['title']);
		$( ".block-modal-text" ).html( data['text']);
		$('#blockmodal').modal('show');		
	});		
	}

	function GetBlock(id){	
	
	$( ".modal-image" ).removeAttr('src'); 
	$.get( "/block/"+id, function( data ) {		
		 if (data['img'] !== 0){
			$( ".modal-image" ).attr('src', '/storage/'+data['img']);
		 } else {
			$( ".modal-image" ).removeAttr('src'); 
		 }		
		
		$( ".block-modal-title" ).html(data['title']);
		$( ".block-modal-text" ).html( data['bigtext']);
		$('#blockmodal').modal('show');		
	});		
	}
	
	

	var timeanim2;
	var timeanim3; 
	var cloudinterval; 
	var cloud1;
	var cloud2;
	
	var t1;
	var t2;
	var t3;
	var t4;
	var t6;
	
	var s2t1;
	var s2t2;
	var s2t3;
	
	
	var s3t1;
	var s3t2;
	var s3t3;
	
	var s4t1;
	var s4t2;
	var s4t3;
	var s4t4;
	var s4t5;
	var clicked;
	var tr1;
	
	var s5t1;
	var rano = 0;
	
	var map;
	////тестовая фича
	function text_scaling($block,text){
        var width_block = $block.width();
        var height_block = $block.height();
        text = text.replace(new RegExp('\r?\n','g'), '<br />');
        $block.find('span').html(text);
      
        if(text){
            var start_font = height_block*0.8;
            var font_size = font_size_determination($block,height_block,width_block,start_font);
            $block.css('font-size',font_size);
        }
     
    }
    function font_size_determination($block,height,width,font_size){
        $block.css('font-size',font_size);
        var block_height = $block.height();
        var block_width = $block.width();
        if (block_height > height || block_width > width){
            font_size = font_size*0.9;
            return font_size_determination($block,height,width,font_size);
        } else {
            return font_size;
        }
    }
	
	function Maps(){
		
		if (map === undefined){
		
		map = DG.map('map', {
                    center: [43.121032,131.879136],
                    zoom: 18,
					animate: true,
					zoomControl : false
                });
		var popupContent = '<div class="leaflet-popup-content-wrapper"><div class="leaflet-popup-content" ><div class="dg-popup__container-wrapper"><div class="dg-popup__container" dir="auto"><div id="module-1-1-2" data-module="firmCallout" class="callout _module_firmCallout _emptyTitle _entrance _wideButton"><address class="callout__address">Фонтанная,&nbsp;6<span class="callout__addressComment"> — 211 офис; 2 этаж</span></address><div class="callout__phone">+7 (423) 262‒02‒77</div><div class="callout__workhours"><a href="http://2gis.ru/vladivostok/firm/70000001006560971/center/131.879173,43.121085/zoom/17?utm_medium=widget&amp;utm_campaign=firmsonmap&amp;utm_source=details" target="_blank" class="link  callout__scheduleToday _scheme_dark">Рабочие дни <span class="callout__scheduleTodayTime">10:00–19:00</span></a></div><div class="callout__workhours"><a href="http://2gis.ru/vladivostok/firm/70000001006560971/center/131.879173,43.121085/zoom/17?utm_medium=widget&amp;utm_campaign=firmsonmap&amp;utm_source=details" target="_blank" class="link  callout__scheduleToday _scheme_dark">Выходные дни <span class="callout__scheduleTodayTime">10:00–12:00</span></a></div><footer class="callout__footer"><div class="callout__btn _details"><a target="_blank" href="http://2gis.ru/vladivostok/firm/70000001006560971/center/131.879173,43.121085/zoom/17?utm_medium=widget&amp;utm_campaign=firmsonmap&amp;utm_source=details" class="link  callout__btnIn callout__details _scheme_none">Узнать больше</a></div></footer></div></div></div></div></div><svg class="leaflet-popup-tip-container leaflet-popup-tip-container_svg"><path d="M0 0c12.643 0 28 7.115 28 44h2c0-36.885 15.358-44 28-44h-58z"></path></svg>';	

			var popup = DG.popup({'minWidth':400}).setContent(popupContent);
	
		DG.marker([43.121032,131.879136]).addTo(map).bindPopup(popup).openPopup();
		//map.openPopup();
		}
	}
	
	////////////////////
	var animation = bodymovin.loadAnimation({
	  container: document.getElementById('bm'),
	  renderer: 'svg',
	  loop: false,
	  autoplay: false,
	  path: './animation/data.json'
	})
	
	var plays21 = bodymovin.loadAnimation({
			  container: document.getElementById('playsection2-1'),
			  renderer: 'svg',
			  loop: false,
			  autoplay: false,
			  path: './animation/02_start.json'
			});
			
		var plays22 = bodymovin.loadAnimation({
				  container: document.getElementById('playsection2-2'),
				  renderer: 'svg',
				  loop: true,
				  autoplay: false,
				  path: './animation/02_loop.json'
				});
		
		var plays31 = bodymovin.loadAnimation({
			  container: document.getElementById('playsection3-1'),
			  renderer: 'svg',
			  loop: false,
			  autoplay: false,
			  path: './animation/03_start.json'
			});
			
		var plays32 = bodymovin.loadAnimation({
				  container: document.getElementById('playsection3-2'),
				  renderer: 'svg',
				  loop: true,
				  autoplay: false,
				  path: './animation/03_loop.json'
				});	


	function StopAnimationSection1(){
		clearInterval(cloudinterval);	
		
		setTimeout(function () {
			cloud1.destroy();
			cloud2.destroy();
			
			
			animation.stop();	
		}, 700);	
	
	}
	
	
	function StartAnimationSection1(){		
		
		$('#bm').css('margin-top','0');
		animation.play();		
		RenderCloud();	
	
		

		cloudinterval = setInterval(function(){
			RenderCloud();
		}, 23000);
	}
	
	
	function StopAnimationSection2(){
		clearTimeout(timeanim2);	
		
		setTimeout(function () {
		$("#playsection2-1").css("opacity", "0");
		$("#playsection2-2").css("opacity", "0");
		plays21.stop();
		plays22.stop();	
		}, 700);	
	
	}
	
		
	function StopAnimationSection3(){
		clearTimeout(timeanim3);	
		setTimeout(function () {
		$("#playsection3-1").css("opacity", "0");
		$("#playsection3-2").css("opacity", "0");
		plays31.stop();
		plays32.stop();	
		}, 700);
		
	
	}
	
	function AnimationSection2(){
	
			$("#playsection2-1").css("opacity", "1");
			plays21.play();
			
			timeanim2 = setTimeout(function () {
				plays22.play();
				$("#playsection2-2").css("opacity", "1");
				$("#playsection2-1").css("opacity", "0");
				},  13000);
	}
	
		
	function AnimationSection3(){
	
			$("#playsection3-1").css("opacity", "1");
			plays31.play();
			
			timeanim3 = setTimeout(function () {
				plays32.play();
				$("#playsection3-2").css("opacity", "1");
				$("#playsection3-1").css("opacity", "0");
				},  33000);
	}

	
		
function CreateScroll(){
	
	$('#multiscroll').multiscroll({
		verticalCentered : true,
		scrollingSpeed: 600,
		easing: 'linear',
		menu: false,
		sectionsColor: [],
		navigation: false,
		navigationPosition: 'right',
		navigationColor: '#000',
		navigationTooltips: [],
		loopBottom: false,
		loopTop: false,
		css3: false,
		paddingTop: 0,
		paddingBottom: 0,
		normalScrollElements: null,
		scrollOverflow: false,
		scrollOverflowOptions: null,
		keyboardScrolling: true,
		touchSensitivity: 5,

		//responsive
		responsiveWidth: 0,
		responsiveHeight: 0,
		responsiveExpand: false,

		// Custom selectors
		sectionSelector: '.ms-section',
		leftSelector: '.ms-left',
		rightSelector: '.ms-right',

		//events
		onLeave: function(index, nextIndex, direction){
			
			if(index == '2' && direction =='down'){
				animS3();
				animS2reset();
				animS4();
			}
			else if(index == '2' && direction == 'up'){
				animS2reset();				
				
				if (!clicked){animS1();};	
				
			}			
			else if(index == '1' && direction == 'down'){
				animS2();	
				animS1reset();
			}		
			
			else if(index == '3' && direction == 'up'){
				if (!clicked){animS2();};	
				animS3reset();
				$('.arrow7l').removeClass('revers');
				
			}			
			else if(index == '3' && direction == 'down'){
				animS3reset();
				
			}
			else if(index == '4' && direction == 'up'){				
				if (!clicked){animS3();};				
				animS4reset();
				$.fn.multiscroll.setMouseWheelScrolling(true);
				$.fn.multiscroll.setKeyboardScrolling(true);				
			}
			else if(index == '4' && direction == 'down'){
				
				$.fn.multiscroll.setMouseWheelScrolling(false);
				$.fn.multiscroll.setKeyboardScrolling(false);			
			}			
			
		
		},
		afterLoad: function(anchorLink, index){
			 if (index <= 3) {
            //activating the scrolling up for any other section
            $.fn.multiscroll.setKeyboardScrolling(true);
        } else {
            //disabling the scrolling up when reaching 1st section
            $.fn.multiscroll.setKeyboardScrolling(false);
        }
		},
		afterRender: function(){
				
		//	animS1reset();
			animS2reset();
			animS3reset();
			animS4reset();
			
			
		},
		afterResize: function(){},
	});
	
	};

	function Vcloud(id, cloud_class, style_init, style_to){	
		$('#'+id).remove();
		$('<div class="cloud '+cloud_class+'" style="'+style_init+'" id="'+id+'"> </div>').appendTo('.cloud_run');
		var lll = bodymovin.loadAnimation({
		  container: document.getElementById(id),
		  renderer: 'svg',
		  loop: false,
		  autoplay: false,
		  path: './animation/cloud01.json'
		});
		
		eval(id+"= lll");
		setTimeout(function(){$('#'+id).css('left',style_to);}, 500);	
	}		
	
	
	function RenderCloud(){	
		setTimeout(function(){
		Vcloud('cloud1', 'left',  'top: '+Math.floor((Math.random() * 100) + 1)+'%;', '100%');
		cloud1.play();
		}, 5000);
		Vcloud('cloud2', 'right',  'top: '+Math.floor((Math.random() * 100) + 1)+'%;', '-100%');	
		
		cloud2.play();	
	}	

	function viewMessageForm(){
		$('.message-form').addClass('active');
		$('.wrapper-right').addClass('dark');
		$('.script-transform-right').addClass('script-active');
	}
	
	function hideMessageForm(){
		$('.message-form').removeClass('active');
		$('.wrapper-right').removeClass('dark');
		$('.script-transform-right').removeClass('script-active');
	}
	
	function hideAddressForm(){
		$('.address-form').removeClass('active');
		$('.script-transform-left').removeClass('script-active');
		$('.wrapper-left').removeClass('dark');
	}
	
	function viewAddressForm(){
		$('.address-form').addClass('active');
		$('.script-transform-left').addClass('script-active');
		$('.wrapper-left').addClass('dark');
	}
	
	function textSection1anim(){		
		
		
		$('.s1textanim2').css({"transition": "all 1s ease"});		
		t2 = setTimeout(function(){$('.s1textanim2').css('opacity','1');}, 1000);
		
		$('.s1textanim4').css({"transform":"translateX(-400px)"}).css({"transition": "all 1s ease"});
		t3 = setTimeout(function(){$('.s1textanim4').css({"transform":"translateX(0) "}).css('opacity','1');}, 1000);
		
		$('.s1textanim1').css({"transition": "all 1s ease"});		
		t1 = setTimeout(function(){$('.s1textanim1').css({"transform":"unset"}).css('opacity','1');}, 1300);
		
		$('.s1textanim6').css({"transform":"translateX(-400px)"}).css({"transition": "all 1s ease"});
		t6 = setTimeout(function(){$('.s1textanim6').css({"transform":"translateX(0)"}).css('opacity','1');}, 1500);
		
		
		
		
		
		
		$('.s1textanim5').css({"transform":"translateY(500px)"}).css({"transition": "all 2s ease"});
		t4 = setTimeout(function(){$('.s1textanim5').css({"transform":"unset"}).css('opacity','1');}, 1900);

		//$('.s1textanim3').css({"transform":"translateX(500px)"}).css({"transition": "all 2s ease"});
		//setTimeout(function(){$('.s1textanim3').css({"transform":"unset"}).css('opacity','1');}, 7000);
		
	}
	
	function textSection1animReset(){
		
		clearTimeout(t1);
		clearTimeout(t2);
		clearTimeout(t3);
		clearTimeout(t4);
		$('.s1textanim1').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateX(400px)"});
		$('.s1textanim2').css({"transition": "all .1s ease"}).css('opacity','0');
	//	$('.s1textanim3').css({"transition": "all .1s ease"}).css('opacity','0');
		$('.s1textanim4').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateX(-400px)"});
		$('.s1textanim6').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateY(-400px)"});
		$('.s1textanim5').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateY(500px)"});
		
	}

	function textSection2anim(){		
		$('.s2textanim1').css({"transition": "all 1s ease"});		
		s2t1 = setTimeout(function(){$('.s2textanim1').css({"transform":"unset"}).css('opacity','1');}, 500);		
		$('.s2textanim2').css({"transition": "all 1s ease"});		
		s2t2 = setTimeout(function(){$('.s2textanim2').css({"transform":"unset"}).css('opacity','1');}, 500);		
		$('.s2textanim3').css({"transition": "all 1s ease"});
		s2t3 = setTimeout(function(){$('.s2textanim3').css({"transform":"unset"}).css('opacity','1');}, 1000);		
	}
	
	function textSection2animReset(){
		clearTimeout(s2t1);
		clearTimeout(s2t2);
		clearTimeout(s2t3);
		$('.s2textanim1').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateX(-500px)"});
		$('.s2textanim2').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateX(500px)"});
		$('.s2textanim3').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateY(400px)"});		
	}	
	
	function textSection3anim(){		
		$('.s3textanim1').css({"transition": "all 1s ease"});		
		$('.s3textanim2').css({"transition": "all 1s ease"});		
		$('.s3textanim3').css({"transition": "all 1s ease"});		
		s3t1 = setTimeout(function(){$('.s3textanim1').css({"transform":"rotate(-90deg)"}).css('opacity','1');}, 500);		
		s3t2 = setTimeout(function(){$('.s3textanim2').css({"transform":"unset"}).css('opacity','1');}, 800);		
		s3t3 = setTimeout(function(){$('.s3textanim3').css({"transform":"unset"}).css('opacity','1');}, 1000);		
	}
	
	function textSection3animReset(){
		clearTimeout(s3t1);
		clearTimeout(s3t2);
		clearTimeout(s3t3);
		$('.s3textanim1').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "rotate(-180deg)"});
		$('.s3textanim2').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateY(-100px)"});
		$('.s3textanim3').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateY(-100px)"});
		
	}
	
	
	function textSection4anim(){		
		$('.s4textanim1').css({"transition": "all 1s ease"});		
		$('.s4textanim2').css({"transition": "all 1s ease"});		
		s4t1 = setTimeout(function(){$('.s4textanim1').css({"transform":"translateY(0px) rotate(180deg)"}).css('opacity','1');}, 500);		
		s4t2 = setTimeout(function(){$('.s4textanim2').css({"transform":"translateY(0px) rotate(180deg)"}).css('opacity','1');}, 500);	
	
	}
	
	function textSection4animReset(){
		clearTimeout(s4t1);
		clearTimeout(s4t2);
		clearTimeout(s4t3);
		clearTimeout(s4t4);
		clearTimeout(s4t5);

		$('.s4textanim1').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateY(800px) rotate(180deg)"});
		$('.s4textanim2').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateY(-800px) rotate(180deg)"});
		
		
	}
	
	function AnimationSection4(){
		
		var index = 0;
		
		s4t4 = setInterval(function(){
		  $('.ms-table.active').find('.advice-panel').eq(index).css({"transition": "all 1s ease"}).css('opacity','1').css({"transform": "translateX(0px)"});		  
		  $('.ms-table.active').find('.advice-panel').eq(index+2).css({"transition": "all 1s ease"}).css('opacity','1').css({"transform": "translateX(0px)"});
		  index++;
		  if(index > $('.ms-table.active').find('.advice-panel').length){
		  clearInterval(s4t4);
		  clearInterval(s4t5);
		}
		}, 500);
		
		s4t5 = setInterval(function(){
		  var kkk = index+2;
		  		  
		  
		}, 500);
		
		s4t3 = setInterval(function(){
		$('.ms-table.active').find('.advice-panel.left').children('.advice-body').children('.advice-body-back').css({"transition": "all 1s ease"}).css('transform','translateY(-10px) translateX(-10px)');
		$('.ms-table.active').find('.advice-panel.right').children('.advice-body').children('.advice-body-back').css({"transition": "all 1s ease"}).css('transform','translateY(-10px) translateX(10px)');
		$('.ms-table.active').find('.advice-panel.left').children('.advice-body').children('.body-text').css({"transition": "all 1s ease"}).css('transform','translateY(-20px) translateX(-20px)');
		$('.ms-table.active').find('.advice-panel.right').children('.advice-body').children('.body-text').css({"transition": "all 1s ease"}).css('transform','translateY(-20px) translateX(20px)');		
		}, 3000);
		
	}
	
	function StopAnimationSection4(){
		
		setTimeout(function () {
		$('.advice-panel').each(function() {
				$( this ).css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateX(-800px)"});
				$( this ).children('.advice-body').children('.body-text').css({"transition": "all .1s ease"}).css('transform','translateX(0px) translateY(0px)');
				$( this ).children('.advice-body').children('.advice-body-back').css({"transition": "all .1s ease"}).css('transform','translateX(0px) translateY(0px)');
				//$(this).children('.advice-body').css({"transition": "all .1s ease"}).children('.body-text').css('top','10px');
			});
		$('.advice.right').children('.advice-panel').each(function() {
				$( this ).css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateX(800px)"});
			});
		
		
		//$('.advice-panel').children('.advice-body').children('.body-text').css({"transition": "all .1s ease"}).css('transform','translateX(0px) translateY(0px)');
		$('.advice-panel').children('.advice-body').children('.advice-body-back').css({"transition": "all .1s ease"}).css('transform','translateX(0px) translateY(0px)');

		
		
	
		}, 700);	
		
		
	}
	
	

	
	function animS1(){
		 //0.5 появление логотипа + 
		 textSection1anim(); 
		setTimeout(function(){StartAnimationSection1(); }, 500);		

		if ($('.arrow7l').not('.active')){
			setTimeout(function(){$('.arrow7l').addClass('active');}, 4000);	
		} 
		$('.arrow7l').off("click", null);
		$('.arrow7l').removeClass('revers');
		$('.arrow7l').on('click',function(){
			$.fn.multiscroll.moveSectionDown();
			clicked = false;
		});
			
				
	}
	
	function animS1reset(){
		setTimeout(function(){
			textSection1animReset();
			//$('.arrow7l').removeClass('active');
			
			}, 500);
		StopAnimationSection1();
	}
	
	function animS2(){
		 textSection2anim();		
		setTimeout(function(){ AnimationSection2();}, 500);			
	}
	function animS2reset(){
		setTimeout(function(){textSection2animReset();}, 800);
		StopAnimationSection2();
	}
	
	
	function animS3(){
		textSection3anim(); 
		setTimeout(function(){AnimationSection3(); }, 500);					
	}

	function animS3reset(){
		setTimeout(function(){textSection3animReset();}, 800);
		StopAnimationSection3();
	}
	
	function resall(){
		
	}
	
	
	function ResetAll(){		
		StopAnimationSection3();		
		animS1();
	}
	
		
	function animS4(){
	//	textSection4anim(); 
	//	setTimeout(function(){AnimationSection4(); }, 1000);
		$('.arrow7l').off("click", null);
		
		$('.arrow7l').addClass('revers');		
		$('.arrow7l').on('click',function(){
			clicked = true;
			$.fn.multiscroll.moveTo(1);		
		
			ResetAll();
		
		});
	}
	
	function animS4reset(){
		setTimeout(function(){textSection4animReset();}, 800);
		StopAnimationSection4();
		
		
	}
	
$(window).resize(function(){
  	if ($(window).width() < $(window).height()) {

        $.get( "/mobile", function( data ) {
		location.reload();
		});


        } 
});	
	

	
$(document).ready(function() {
	




$('#sendmessage').on('click',function(e){
		 var formdata = $('.message7l').serialize(); // here $(this) refere to the form its submitting
	
    $.ajax({
        type: 'POST',
        url: "/message",
        data: formdata, // here $(this) refers to the ajax object not form
        success: function (data) {
           $( ".block-modal-title" ).html('Успешная отправка');
			$( ".block-modal-text" ).html('Ваше сообщение успешно отправлено');
			$('#blockmodal').modal('show');	
        },
    });
	e.preventDefault(); 
	
	});
	
	$(".form-check-input").change(function() {
    if(this.checked) {
        $('#sendmessage').removeAttr('disabled');
    } else {
		  $('#sendmessage').attr("disabled", true);
	}
	});
	
	$("#phone").inputmask("+7(999)999-99-99");
	
	
	 CreateScroll();	
	$('.sect1_left').css('transition',' all .1s ease').css('top','100%');
	$('.sect1_right').css('transition',' all .1s ease').css('top','-100%');
	$('.sect_left').css('transition',' all .5s ease');
	$('.sect_right').css('transition',' all .5s ease');
	
	
	
	
	
	
	setTimeout(function(){
	
	$('.sect1_right').css('transition',' all .5s linear').css('top','0%');
	$('.sect1_left').css('transition',' all .5s linear').css('top','0%');

	
	animS1();
	}, 1000	);		
	
	
	textSection1animReset();
	
	$('.click_text').on('click',function(){
		view_page($(this).attr('data-id'));
		//console.log($(this).attr('data-id'));
	});
	
	
	
	 $('.blockmodal').on('click',function(){
		 GetBlock($(this).attr('data-id'));
	
	 });
	
	
	$('.doclegal').on('click',function(){
		GetDoc('legal');
	});
	
	
	$('.doccontract').on('click',function(){
		GetDoc('contract_pr');
	});
	
	
	$('.menu-li').on('click',function(){
		view_page($(this).attr('data-id'));
		//console.log($(this).attr('data-id'));
	});
	
	
	
	$('.script-address-form').on('click',function(){
		//hideAddressForm();
	});
	
	
	$('.script-message-form').on('click',function(){
		//hideMessageForm();
	});
	
	$('.script-transform-left').on('click',function(){
	    hideMessageForm();
		Maps();	
		viewAddressForm();	
	});
	
	$('.script-transform-right').on('click',function(){
		hideAddressForm();
		viewMessageForm();			
	});
	
var $toggleButton = $('.toggle-button'),
        $menuWrap = $('.menu-wrap');
		$toggleButton.on('click', function() {
        $(this).toggleClass('button-open');
        $menuWrap.toggleClass('menu-show');		
    });
	
	$('.menu').on('click',function(){
		
		
		clearTimeout(tr1);
		//tr1 = setTimeout(function(){$('.trans-form').toggleClass('active');}, 1500);
		
		$('.wrapper-left').toggleClass('dvis');
		$('.wrapper-right').toggleClass('dvis');
		$('.menu').toggleClass('m-top-15',400);
		$('.menu-list').toggleClass('dnonez').toggleClass('menu-show').toggleClass('show');
		$('.menu-li').toggleClass('show-li');
		$('.menu-form').toggleClass('menu-show').toggleClass('show');
		$('.menu-wrapper').toggleClass('wrapper-show');
		$('.ms-table.active').toggleClass('menu-active-section');
		$('.trans-form').removeClass('active');
		hideAddressForm();
		hideMessageForm();
		
		if ($('.menu-list').hasClass('menu-show')) {			
			$.fn.multiscroll.setMouseWheelScrolling(false);
			$.fn.multiscroll.setKeyboardScrolling(false);
			setTimeout(function(){$('.trans-form').addClass('active');}, 250);
			$('.pages.active').addClass('pages-menu-active');
		
		} else {
			if (!$('.pages').hasClass('active')){
			$.fn.multiscroll.setMouseWheelScrolling(true);
			$.fn.multiscroll.setKeyboardScrolling(true);
			
			}
			$('.pages').removeClass('pages-menu-active');
			$('.trans-form').removeClass('active');
			
		}
		
	});
	
	
	

	
	
	
		function view_page(id){
			if (!$('.pages-left.page'+id).hasClass('active')){
				$('.trans-form').removeClass('active');
				$('.page'+rano).addClass('zed');
				
				//setTimeout(function () {$('.page'+rano).css('transition','top 1s ease, left 1s ease, right 1s ease, margin-right .5s ease, margin-left .5s ease')} ,650);
			
			$('.containpage'+id).removeClass('dnone');
		
		setTimeout(function () {	
			$('.pages-left.page'+id).addClass('active');
			$('.pages-right.page'+id).addClass('active');
		},300)
		
			$('.arrow7l').css('display','none');
			textSection1animReset();
			textSection2animReset();
			textSection3animReset();
			textSection4animReset();
			StopAnimationSection3();
			StopAnimationSection2();
			StopAnimationSection1();
			StopAnimationSection4();
			setTimeout(function () {
					$('.page'+rano).removeClass('active').removeClass('zed');
					$('.page'+rano).find('.advice-body-back').removeAttr( 'style' );
					$('.page'+rano).find('.body-text').removeAttr( 'style' );
					$('.page'+rano).find('.page-panel').removeAttr( 'style' );
					$('.containpage'+rano).addClass('dnone');
					$('body,html').animate({scrollTop: 0}, 0);
				} ,200);
				
					setTimeout(function () {
				
				if ($('.menu').hasClass('button-open')){
					$('.menu').click(); 
					rano = id;
				} else {
					rano = id;
				}
				} ,700);
			viewpage(id);
		//	$.fn.multiscroll.destroy();
			$('body').css('overflow-y','initial');
			$('html').css('overflow-y','initial');
			$('.mainlogo').addClass('dnone');
			$('.ms-left').css('display','none');
			$('.ms-right').css('display','none');
			}
		
	}
	
	
	function opatext(leftpanel, rightpanel, timeout){
		s5t2 = setTimeout(function(){
		$(leftpanel).children('.advice-body').children('.advice-body-back').css({"transition": "all "+timeout+" ease"}).css('transform','translateY(-10px) translateX(-10px)');
				$(rightpanel).children('.advice-body').children('.advice-body-back').css({"transition": "all "+timeout+" ease"}).css('transform','translateY(-10px) translateX(10px)');
				$(leftpanel).children('.advice-body').children('.body-text').css({"transition": "all "+timeout+" ease"}).css('transform','translateY(-20px) translateX(-20px)').css('opacity','1');
				$(rightpanel).children('.advice-body').children('.body-text').css({"transition": "all "+timeout+" ease"}).css('transform','translateY(-20px) translateX(20px)').css('opacity','1');		
				}, 1300);
		
	}
	
	function viewpage(idpage){
	
			  
			
			panels =  $('.containpage'+idpage).find('.page-panel');
			leftpanel = $('.containpage'+idpage).find('.page-panel.left');
			rightpanel = $('.containpage'+idpage).find('.page-panel.right');
		//	console.log('.pages-left.page'+idpage+'.active '+$(panels).length);
			var index2 = 0;		
				s5t1 = setInterval(function(){
				  $(panels).eq(index2).css({"transition": "all 1s ease"}).css('opacity','1').css({"transform": "translateX(0px)"});	
				//  console.log(index2);
				//  console.log(index2);
					if(index2 >= $(panels).length){
					  clearInterval(s5t1);		
					}
				  index2++;		  
				}, 500);	
		
			if (idpage == 1 || idpage == 2){
				
				opatext(leftpanel, rightpanel, "500ms");
				
			} else {
				
				opatext(leftpanel, rightpanel, "1s");
				
			}	
		
			
	}
	
	
	
});







